<template>
  <list-container-widget :title="widgetName">
    <template #actions>
      <ViewAllButton item-type="auditorium" :isFullScreen=true />
    </template>
    <!-- Loading -->
    <div v-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>
    <!-- Container with data -->
    <div v-else-if="mainRoom">
      <auditorium />
    </div>

    <!-- Container without data: Placeholder -->
    <b-row
      v-else-if="placeholder || placeholderMessage"
      class="horizontal-placeholder"
    >
      <b-col v-if="placeholder" cols="12">
        <img :src="placeholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message-singular", { itemName: widgetName }) }}
        </p>
      </b-col>
    </b-row>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import RoomsPlaceholder from "@/assets/images/placeholders/light/rooms.svg";
import { getImageResource } from "@/@core/utils/image-utils";
import Service from "@/config/service-identifiers";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import { quillEditor } from "vue-quill-editor";
// import vSelect from "vue-select";
import Auditorium from '@/views/apps/auditorium/Auditorium.vue';
// import FileTypes from "@/@core/constants/FileTypes";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
// import { RoomApp } from "@copernicsw/community-common";

export default {
  name: "MainRoomWidget",
  components: {
    ListContainerWidget,
    Auditorium,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      placeholder: RoomsPlaceholder,
      loadingNext: false,
      projects: [],
      items: [],
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      userCanAccess: "",
      formInput: {
        name: null,
        description: "",
        modality: null,
        app: null,
        type: null,
      },
      itemImage: null,
      imageSrc: null,
      selectedOption: this.$t("rooms.online"),
      selectedApp: "Conferencing",
      selectedType: "Meeting",
      questionsToShow: [],
      appRoom: [],
      roomName: false,
    };
  },
  computed: {
    appsOptions() {
      return this.appRoom;
    },
    typeEventsOptions() {
      return [
        this.$t("rooms.online"),
        this.$t("rooms.on-site"),
        this.$t("rooms.hybrid"),
      ];
    },
    typeOptions() {
      return ["Meeting", "Webinar"];
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.rooms;
    },
    mainRoom() {
      let room = this.itemsData.unpaginated?.find(
        (room) => room.isMain === true
      );
      if (room) {
        this.roomName = room.name;
      }

      return this.itemsData.unpaginated?.find((room) => room.isMain === true);
    },
    joinRoomLocation() {
      return {
        name: "main-room",
        params: {
          id: this.mainRoom.key,
          communityId: this.$route.params.communityId,
        },
      };
    },
    canAccess() {
      if (this.mainRoom && this.mainRoom.Meetings.length > 0) {
        return this.mainRoom.Meetings.some(
          (item) => item.isAccepted || item.isSection || this.userCanAccess
        );
      }
      return this.userCanAccess;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData(true);
    this.isLoading = false;
    this.videocallUsersCanAccessNow();
    await this.getApp();
  },
  methods: {
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    // TODO: better to use store state
    async fetchData(force = false) {
      await this.$store.dispatch("getItems", {
        itemType: "rooms",
        page: this.lastLoadedPage,
        forceAPICall: force,
        // Workaround to ensure that the main room is located
        // TODO: request second page if mainRoom is not found
        perPage: 1000,
        requestConfig: {
          orderByDate: -1,
          forceAPICall: force,
        },
      });
      this.isLoading = false;
    },
    async videocallUsersCanAccessNow() {
      try {
        // TODO: use member store
        const response = await this.$store.$service[Service.BackendClient].get(
          "/videocallUsersCanAccessNow",
          {
            params: {
              roomKey: this.mainRoom.key,
            },
          }
        );
        this.userCanAccess = response.data.responseData.ok;
      } catch (error) {
        this.userCanAccess = false;
      }
    },
    location() {
      this.mainRoom.joinURL
        ? (window.location.href = this.mainRoom.joinURL)
        : "";
    },
    resetInputs() {
      this.formInput = {};
      this.itemImage = null;
      this.imageSrc = null;
    },
    async handleCreateItem() {
      if (!this.formInput.name) {
        this.formError = false;
        return;
      }
      try {
        this.modality();
        this.appss();
        this.type();
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "rooms",
            requestConfig: {
              name: this.formInput.name,
              description: this.formInput.description,
              modality: this.formInput.modality,
              app: this.formInput.app != "" ? this.formInput.app : null,
              type: this.formInput.type,
              isMain: 1,
            },
          },
          file: this.itemImage,
        });
        this.fetchData(true);
        this.videocallUsersCanAccessNow();
        this.resetInputs();
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
        (this.selectedOption = this.$t("rooms.online")),
          (this.selectedApp = "Conferencing");
        this.selectedType = "Meeting";
      } catch (e) {
        console.log(e);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async getApp() {
      // const response = await this.$store.$service[Service.BackendClient].get('addons/rooms/installed', {
      //   params: { communitySlug: this.communitySlug },
      // });
      // this.appRoom[0] = 'Conferencing';
      // if (RoomApp) {
      //   for (const row of response.data) {
      //     this.appRoom.push(row.name.en);
      //   }
      this.appRoom = [
        "Bluejeans",
        "Nectios Classroom",
        "Conferencing",
        "Zoom.us",
        "Jisti",
      ];
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = "physical";
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = "hybrid";
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = "online";
      }
    },
    appss() {
      switch (this.selectedApp) {
        case "Conferencing":
          this.formInput.app = "jitsi";
          return;
        case "Zoom.us":
          this.formInput.app = "zoom";
          return;
        case "Nectios Classroom":
          this.formInput.app = "classroom";
          return;
        case "Bluejeans":
          this.formInput.app = "bluejeans";
          return;
        case "Jisti":
          this.formInput.app = "jitsi";
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = "meeting";
          return;
        case this.typeOptions[1]:
          this.formInput.type = "webinar";
      }
    },
  },
};
</script>
<style>
.image-content > img {
  max-height: 200px;
  min-width: 250px;
  margin: auto;
}
.auditorium-description {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
}
.join-button {
  margin: auto;
  margin-top: 10px;
  padding-bottom: 24px;
  max-width: 325px;
}
</style>
